import React from 'react';
import appStoreDownloadSvg from '../../images/appstoredownload.svg';
import './AppStoreDownload.scss';

class AppStoreDownload extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div ref={this.props.innerRef} className="appstore-download">
        <a href="https://apps.apple.com/us/app/mosaic-personal-finance/id1559841799">
          <img
            src={appStoreDownloadSvg}
            alt={'App Store Download Button'}
            style={{
              height: `${this.props.height}px`,
            }}
          ></img>
        </a>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <AppStoreDownload innerRef={ref} {...props} />
));
