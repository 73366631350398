import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import logo from '../../images/logo.svg';
import mosaic from '../../images/mosaic.svg';

import PageTitles from '../pagetitles';

import './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { navOpen: false };
    this.navBurger = React.createRef();
  }

  toggleMenu = () => {
    this.setState({ navOpen: !this.state.navOpen }, () => {
      document.body.style.overflow = this.state.navOpen ? 'hidden' : 'auto';
      this.navBurger.current.classList.toggle('open');
    });
  };

  resetScroll = () => {
    document.body.style.overflow = 'auto';
  };

  render() {
    return (
      <header
        className={
          this.state.navOpen
            ? `nav-open ${this.props.theme}`
            : `${this.props.theme}`
        }
      >
        <nav id="main-nav">
          <Link to="/">
            <div className="nav-logo">
              <img alt={'Mosaic logo'} id="header-logo" src={logo}></img>
              <img
                alt={'Mosaic logo text'}
                id="header-mosaic"
                src={mosaic}
              ></img>
            </div>
          </Link>
          <ul
            className={this.state.navOpen ? 'nav-open' : ''}
            ref={this.navLinksRef}
          >
            {/* TODO: clean up li's, combine into component */}
            <li
              className={
                this.props.activePage === PageTitles.Home
                  ? 'nav-link-selected'
                  : ''
              }
              onClick={this.resetScroll}
            >
              <Link to="/">{PageTitles.Home}</Link>
            </li>
            <li
              className={
                this.props.activePage === PageTitles.About
                  ? 'nav-link-selected'
                  : ''
              }
              onClick={this.resetScroll}
            >
              <Link to="/about">{PageTitles.About}</Link>
            </li>
            <li
              className={
                this.props.activePage === PageTitles.Feedback
                  ? 'nav-link-selected'
                  : ''
              }
              onClick={this.resetScroll}
            >
              <OutboundLink href="mailto:feedback@mosaicmoney.app">{PageTitles.Feedback}</OutboundLink>
            </li>
            <li
              className={
                this.props.activePage === PageTitles.Blog
                  ? 'nav-link-selected'
                  : ''
              }
              onClick={this.resetScroll}
            >
              <OutboundLink href="https://blog.mosaicmoney.app">
                {PageTitles.Blog}
              </OutboundLink>
            </li>
          </ul>
          <div
            className="nav-burger"
            onClick={this.toggleMenu}
            ref={this.navBurger}
            onKeyDown={e => {
              if (e.keyCode === 13) this.toggleMenu();
            }}
            role={'button'}
            // tabIndex={0}   // TODO: for a11y, fix styling and reenable.
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  activePage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  theme: state.theme,
});

export default connect(mapStateToProps)(Header);
