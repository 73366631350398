import React from 'react';
import './Footer.scss';
import logo from '../../images/logo.svg';
import EmailForm from '../EmailForm';

import twitterfooter from '../../images/twitterfooter.svg';
import igfooter from '../../images/igfooter.svg';
import blogfooter from '../../images/blogfooter.svg';
import emailfooter from '../../images/emailfooter.svg';
import AppStoreDownload from '../AppStoreDownload/AppStoreDownload';

export default function Footer({}) {
  return (
    <footer>
      <div id="footer-content">
        <div id="footer-brand">
          <img id="footer-logo" src={logo}></img>
        </div>
        <div id="footer-reach">
          <div id="footer-socials">
            <a href={'https://twitter.com/mosaicmoney/'} target="_blank">
              <img alt={'Twitter'} src={twitterfooter}></img>
            </a>
            <a
              href={'https://www.instagram.com/mosaicmoney.app/'}
              target="_blank"
            >
              <img alt={'Instagram'} src={igfooter}></img>
            </a>
            <a href={'https://blog.mosaicmoney.app/'} target="_blank">
              <img alt={'Blog'} src={blogfooter}></img>
            </a>
            <a href={'mailto:hello@mosaicmoney.app'}>
              <img alt={'Email'} src={emailfooter}></img>
            </a>
          </div>
        </div>
        <div id="footer-border"></div>
        <div id="footer-downloadbutton">
          <AppStoreDownload height={40} />
        </div>
        <div id="footer-legal">
          <div>
            <a className="mosaic-legal-link" href="/privacy">
              Privacy Policy
            </a>
            <a className="mosaic-legal-link" href="/terms">
              Terms of Use
            </a>
          </div>
          <p>
            {/* use gtag Outbound links here  */}
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://policies.google.com/privacy"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://policies.google.com/terms"
            >
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </div>
      </div>
    </footer>
  );
}
