import { createStore } from 'redux'

const initialState = {
  theme: 'light'
}

const reducer = (state = initialState, action) => {
  return Object.assign({}, state, {theme: (action.payload ? action.payload : 'light')});
}

const store = createStore(reducer)

export default store