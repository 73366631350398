/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Provider } from 'react-redux'
import store from '../../store';

import Header from '../Header';
import Footer from '../Footer';

import './Layout.scss';

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string
};

export default function Layout({ children, pageTitle, hideFooter = false }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Provider store={store}>
      <Header siteTitle={data.site.siteMetadata.title} activePage={pageTitle} />
      <main>{children}</main>
      {!hideFooter && <Footer />}
    </Provider>
  );
}
