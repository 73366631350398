import React, { useRef, useState } from 'react';
import './WaitlistModalContent.scss';

import close from '../../images/close.svg';
import tweetref from '../../images/tweetref.svg';
import emailref from '../../images/emailref.svg';
import nativeref from '../../images/nativeref.svg';
import { useEffect } from 'react';

export default function WaitlistModalContent(props) {
  const refLinkInputRef = useRef(null);

  const [result, setResult] = useState(JSON.parse('{}'));
  const [copyText, setCopyText] = useState('COPY');
  const [shareAvailable, setShareAvailable] = useState(false);

  useEffect(() => {
    setResult(props.result);
    if (navigator && navigator.share) {
      setShareAvailable(true);
    }
  }, [props.result]);

  function copyLink() {
    refLinkInputRef.current.select();
    refLinkInputRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopyText('✓');
    setTimeout(() => {
      setCopyText('COPY');
    }, 1500);
  }

  function clickTweetRef() {
    var url = `http://twitter.com/share?text=Check%20out%20%40mosaicmoney!&url=https://mosaicmoney.app/?ref=${result.code}&hashtags=personalfinance,investing,FIRE`;
    window.open(url, '_blank');
  }
  function clickEmailRef() {
    var url = `mailto:?body=Check out Mosaic! https://mosaicmoney.app/?ref=${result.code}`;
    var mail = document.createElement('a');
    mail.href = url;
    mail.click();
  }
  function clickNativeRef() {
    if (navigator && navigator.share) {
      navigator
        .share({
          title: 'Mosaic',
          url: `https://mosaicmoney.app/?ref=${result.code}`,
        })
        .then(() => {
          alert('Thanks for sharing!');
        })
        .catch(console.error);
    }
  }

  return (
    <>
      <div id="modal-header">
        <p id="header-title">
          <span className={'email'}>{result.email}</span>
          <span className="hide-mobile"> is on the list!</span>
        </p>
        <img
          alt={'Close button'}
          id="close-button"
          onClick={props.handleCloseModal}
          src={close}
        ></img>
      </div>
      <div id="modal-body">
        <div id="modal-ticket">
          <h2 id="modal-ahead-label">
            {Number(result.position - 1).toLocaleString()}{' '}
            {result.position - 1 === 1 ? 'person' : 'people'} ahead of you
          </h2>
          <p className="modal-ticket-ref-label">
            Move up the list by inviting others.
          </p>
          <p className="modal-ticket-ref-label">
            You have referred a total of{' '}
            <b>
              {Number(result.referrals).toLocaleString()}{' '}
              {result.referrals === 1 ? 'person' : 'people'}
            </b>
            .
          </p>
        </div>
        <div id="modal-invite">
          <p id="modal-invite-label">Invite friends:</p>
          <div id="socialrefs">
            <img
              alt={'Native Share'}
              onClick={clickNativeRef}
              src={nativeref}
              style={{
                display: shareAvailable ? 'block' : 'none',
              }}
            ></img>
            <img alt={'Twitter'} onClick={clickTweetRef} src={tweetref}></img>
            <img alt={'Email'} onClick={clickEmailRef} src={emailref}></img>
          </div>
        </div>
        <div id="modal-share">
          <p id="modal-share-label">or share your referral link:</p>
          <div id="linkref">
            <input
              ref={refLinkInputRef}
              readOnly
              value={'https://mosaicmoney.app/?ref=' + result.code}
            ></input>
            <button className="copy-button" onClick={copyLink}>
              {copyText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
