/**
 * Component to subscribe users to mailing list.
 * Uses Google's invisible reCaptcha v2.
 */

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './EmailForm.scss';
import WaitlistModalContent from '../WaitlistModalContent';

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, input: '', result: '', isModalOpen: false };
    this.recaptchaRef = React.createRef();
    this.emailRef = React.createRef();
  }

  handleSubmit = e => {
    e.preventDefault();
    if (process.env.GATSBY_FORM_UI_ONLY) {
      let r = {};
      r.email = 'momin.muin@gmail.com';
      r.code = 'abc123';
      r.position = 18;
      r.referrals = 10;
      this.setState({ result: r }, () => {
        this.openModal();
      });
      return;
    }

    if (this.validateEmail(this.state.input)) {
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.execute();
      }
      this.setState({ loading: true });
    } else {
      window.alert('Not a valid email');
      this.emailRef.current.focus();
    }
  };

  validateEmail = email => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  onChange = async value => {
    if (value && this.state.input.length > 0) {
      this.setState({ loading: true });

      const refferalCode = new URLSearchParams(window.location.search).get(
        'ref'
      );

      try {
        const response = await fetch('https://api.getmosaic.io/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify({
            email: this.state.input,
            captcha: value,
            ref: refferalCode ? refferalCode : '',
          }),
        });
        if (response.ok) {
          const res = JSON.parse(await response.text());
          this.setState({ result: res }, () => {
            this.openModal();
          });
        } else {
          console.log('Error in response');
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.recaptchaRef.current.reset();
        this.setState({ loading: false, input: '' });
      }
    } else {
      this.setState({ loading: false });
    }
  };

  onError = err => {
    console.log(err);
  };

  openModal = () => {
    this.setState({ isModalOpen: true }, () => {
      document.body.style.overflow = this.state.isModalOpen ? 'hidden' : 'auto';
    });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false }, () => {
      document.body.style.overflow = this.state.isModalOpen ? 'hidden' : 'auto';
    });
  };

  render() {
    return (
      <>
        <form ref={this.props.innerRef} onSubmit={this.handleSubmit}>
          <input
            ref={this.emailRef}
            value={this.state.input ? this.state.input : ''}
            placeholder="Email"
            disabled={this.state.loading}
            onChange={event => this.setState({ input: event.target.value })}
          ></input>
          <button onClick={this.handleSubmit} disabled={this.state.loading}>
            {this.state.loading ? 'LOADING...' : 'GET EARLY ACCESS'}
          </button>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey="6Lfk-NQUAAAAAMK1D05q92opvN4Tz0JADtK7XqvF" // public
            onChange={this.onChange}
            onErrored={this.onError}
          />
        </form>
        <div
          style={{ display: this.state.isModalOpen ? 'block' : 'none' }}
          className="modal-overlay"
        ></div>
        <div
          className="modal"
          style={{
            display:
              this.state.isModalOpen && this.state.result ? 'block' : 'none',
          }}
        >
          <WaitlistModalContent
            handleCloseModal={this.closeModal}
            result={this.state.result}
          />
        </div>
      </>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <EmailForm innerRef={ref} {...props} />
));
